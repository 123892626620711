import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import PostCard from "../components/postCard"

import mockupImage from "../../content/assets/mockups/hero.png"
import colorsImage from "../../content/assets/mockups/colors.png"
import mesh1Image from "../../content/assets/mockups/mesh-1.jpg"
import mesh2Image from "../../content/assets/mockups/mesh-2.jpg"
import mockupGradient from "../../content/assets/mockups/mockup-gradient.png"
import website from "../../content/assets/mockups/website.png"
import presentation from "../../content/assets/mockups/presentation.png"
import appstore1 from "../../content/assets/mockups/appstore-1.png"
import appstore2 from "../../content/assets/mockups/appstore-2.png"
import mockup1 from "../../content/assets/mockups/1.png"
import mockup2 from "../../content/assets/mockups/2.png"
import mockup3 from "../../content/assets/mockups/3.png"
import mockup4 from "../../content/assets/mockups/4.png"
import mockup5 from "../../content/assets/mockups/5.png"
import mockup6 from "../../content/assets/mockups/6.png"
import mockup7 from "../../content/assets/mockups/7.png"
import mockup8 from "../../content/assets/mockups/8.png"
import mockup9 from "../../content/assets/mockups/9.png"
import mockup10 from "../../content/assets/mockups/10.png"
import mockup11 from "../../content/assets/mockups/11.png"
import scene from "../../content/assets/mockups/scene.png"
import purchase from "../../content/assets/mockups/purchase.png"
import figmaLogo from "../../content/assets/mockups/figma-logo.png"
import sketchLogo from "../../content/assets/mockups/sketch-logo.png"
import { Parallax } from "react-scroll-parallax"
import { window } from "browser-monads"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"

let aosInitialized = false

const images = [
  mockupImage,
  colorsImage,
  mesh1Image,
  mesh2Image,
  mockupGradient,
  website,
  presentation,
  appstore1,
  appstore2,
  mockup1,
  mockup3,
  mockup4,
  mockup5,
  mockup6,
  mockup7,
  mockup8,
  mockup9,
  mockup10,
  mockup11,
  scene,
  purchase,
  figmaLogo,
  sketchLogo,
]

const imagesLoaded = false
const imagesLoadingInProgress = false

const buy = e => {
  e.preventDefault()
  const isProd = window.location.host === "minima.palapa.tech"
  const stripe = window.Stripe(
    isProd
      ? "pk_live_51HNdz1LRGRGFr0QBaGX6UuVJKa8iT8S2vTlXhoRAsMBLWSPMPlpgcga6wYhfHLCad2YSAvtiwgR13O2jbSCPeVhF001qGX7a60"
      : "pk_test_51HNdz1LRGRGFr0QBI7CxUgrX7KzUNJB1L6SudYsGMI6f6Aq28fESdkn6CzfFI6oTrmyPoZoHL0dywvLJaany8Flx00Ok7ABCO5"
  )
  stripe.redirectToCheckout({
    lineItems: [
      {
        price: isProd
          ? "price_1HcM83LRGRGFr0QBIfz8lE13"
          : "price_1HcM7rLRGRGFr0QBrVwW5356",
        quantity: 1,
      },
    ],
    mode: "payment",
    successUrl:
      window.location.protocol +
      (!isProd
        ? "//localhost:8000/G5Y2GPlu8YGYbB6Sl9CmNY0unrkV4BeHw2fTfHgg"
        : "//minima.palapa.tech/G5Y2GPlu8YGYbB6Sl9CmNY0unrkV4BeHw2fTfHgg"),
    cancelUrl:
      window.location.protocol +
      (!isProd ? "//localhost:8000" : "//minima.palapa.tech/"),
  })
}

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const [isImagesLoaded, setIsImagesLoaded] = useState(false)
  const siteTitle = data.site.siteMetadata.title
  const mockups = [
    mockup1,
    mockup2,
    mockup3,
    mockup4,
    mockup5,
    mockup6,
    mockup7,
    mockup8,
    mockup9,
    mockup10,
    mockup11,
  ]
  Promise.all(
    images.map(
      item =>
        new Promise(resolve => {
          let timedOut = false
          const timeout = setTimeout(() => {
            timedOut = true
            resolve()
          }, 5000)
          const image = new Image()
          image.src = item
          image.onload = () => {
            if (timedOut) {
              return
            }
            clearTimeout(timeout)
            resolve()
          }
        })
    )
  ).then(() => setIsImagesLoaded(true))
  if (!isImagesLoaded) {
    return (
      <div className="page-loader">
        <div className="loader" />
      </div>
    )
  }
  let aosInterval = setInterval(() => {
    if (!aosInitialized && window.AOS) {
      window.AOS.init()
      clearInterval(aosInterval)
    }
  }, 200)
  return (
    <Layout title={siteTitle}>
      <SEO
        title="Home"
        keywords={[
          `mockups`,
          `graphic`,
          `design`,
          `iphone`,
          "iphone 12",
          "iphone 12 mockups",
        ]}
      />
      {/* <Bio /> */}
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <div className="page-head-content">
            <h2 data-aos="fade-up" className="page-head-title">
              {data.site.siteMetadata.description}
            </h2>
            <p data-aos-delay="200" data-aos="fade-up">
              Clean, easy-customizable high-res mockups in 11 different angles!
              <div className="page-head-logos">
                <div className="page-head-logo-item">
                  <img src={figmaLogo} />
                </div>
                <div className="page-head-logo-item">
                  <img src={sketchLogo} />
                </div>
              </div>
            </p>
          </div>
          <div
            data-aos-delay="300"
            data-aos="fade-up"
            className="page-head-image"
          >
            <Parallax y={[100, -100]}>
              <img src={mockupImage} />
            </Parallax>
          </div>
        </header>
      )}
      <div className="feature">
        <div
          className="feature-content"
          data-aos="fade-up"
          style={{ backgroundImage: `url(${mesh2Image})` }}
        >
          <div className="feature-content-text">Create beautiful scenes</div>
        </div>
        <div className="feature-image feature-image--static">
          <div className="feature-image-wrap">
            <img src={scene} />
          </div>
        </div>
      </div>
      <div className="feature feature--colors">
        <div className="feature-image">
          <Parallax x={[-200, 100]} tagOuter="figure">
            <div className="feature-image-wrap">
              <img src={colorsImage} />
            </div>
          </Parallax>
        </div>
        <div
          className="feature-content"
          data-aos="fade-up"
          style={{ backgroundImage: `url(${mesh1Image})` }}
        >
          <div className="feature-content-text">Easily change colors!</div>
        </div>
      </div>
      <div className="feature">
        <div
          className="feature-content"
          data-aos="fade-up"
          style={{ backgroundImage: `url(${mesh2Image})` }}
        >
          <div className="feature-content-text">
            WOW! You can even add a gradient!
          </div>
        </div>
        <div className="feature-image feature-image--static" data-aos="fade-up">
          <div className="feature-image-wrap">
            <img src={mockupGradient} />
          </div>
        </div>
      </div>
      <div className="usecase usecase--two">
        <div className="usecase-col">
          <div className="usecase-item" data-aos="fade-up">
            <div className="usecase-item-title">
              For beautiful presentations!
            </div>
            <div className="usecase-item-image">
              <img src={presentation} />
            </div>
          </div>
          <div className="mobile-only usecase-item" data-aos="fade-up">
            <div className="usecase-item-title">Stunning appstore assets!</div>
            <div className="usecase-item-image usecase-item-image--appstore">
              <div className="usecase-item-image-item">
                <img src={appstore1} />
              </div>
              <div className="usecase-item-image-item">
                <img src={appstore2} />
              </div>
            </div>
          </div>
          <div className="usecase-item" data-aos="fade-up">
            <div className="usecase-item-title">Even for websites!</div>
            <div className="usecase-item-image">
              <img src={website} />
            </div>
          </div>
        </div>
        <div className="usecase-col desktop-only">
          <div className="usecase-item" data-aos="fade-up">
            <div className="usecase-item-title">Stunning appstore assets!</div>
            <div className="usecase-item-image usecase-item-image--appstore">
              <div className="usecase-item-image-item">
                <img src={appstore1} />
              </div>
              <div className="usecase-item-image-item">
                <img src={appstore2} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="showcase">
        <div className="showcase-title">11 Different angles!</div>
        <div className="showcase-content">
          {mockups.map((item, idx) => (
            <div className="showcase-item" key={idx} data-aos="fade-up">
              <img src={item} />
            </div>
          ))}
        </div>
      </div>
      <div className="download">
        <div
          className="download-content"
          data-aos="fade-right"
          style={{ backgroundImage: `url(${mesh1Image})` }}
        >
          <div className="download-content-title">
            Minima iPhone 12 mockups pack
          </div>
          <div className="download-content-features">
            <ul>
              <li>Compatible with Figma and Sketch</li>
              <li>11 different angles</li>
              <li>Hi-res images</li>
              <li>Everything clear, grouped and easy to use</li>
              <li>Instructions included</li>
            </ul>
          </div>
          <div className="download-content-buy">
            <a href="#" onClick={buy} className="button primary large">
              Buy for $9.95
            </a>
          </div>
        </div>
        <div className="download-image" data-aos="fade-left">
          <img src={purchase} />
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
